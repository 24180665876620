<template>
  <div class="iss-main theme-bg" ref="container">
    <div class="correction-content">
      <tips-bar
        text="请对公司名称和职位名称进行规范修正，以便于批量整理联系人。"
      >
        <template #btnList>
          <!--          <a-popconfirm-->
          <!--            placement="left"-->
          <!--            ok-text="确认"-->
          <!--            cancel-text="取消"-->
          <!--            @confirm="clickHandle"-->
          <!--          >-->
          <!--            <template #title>-->
          <!--              <p>请确认您联系人公司和职位名称都已修正完成！</p>-->
          <!--            </template>-->
          <!--            -->
          <!--          </a-popconfirm>-->
          <div class="btn" @click="clickHandle">完成</div>
        </template>
      </tips-bar>
      <div class="operation">
        <div class="operation-header">
          <div class="header-text">
            <a-radio-group v-model:value="typeValue" button-style="solid" @change='handleRadio'>
              <a-radio-button value="1">公司名称</a-radio-button>
              <a-radio-button value="2">职位名称</a-radio-button>
            </a-radio-group>
          </div>
        </div>
        <div class="operation-table">
          <grid
            ref="gridRef"
            :columns="typeValue === '1' ? columns : columnsPos"
            :code="$route.path"
            :items="items"
            :url="url"
            :btn-operation="btnOperation"
            :urlParams="{
              type: typeValue,
              userId: $store.state.account.user.id,
              importSituation: $route.query.importStatus,
            }"
            rowKey="name"
            selectRowKey="name"
            :showHeader="true"
            :rowClassName="record => (record.isRepeat === 0 ? 'redBg' : null)"
            :defaultValue="{
              status: '',
            }"
            :class="{
              bgHove: record => (record.isRepeat === 1 ? true : false),
            }"
            :allowSelection="{
              hideAllSelect: true,
              type: 'checkbox',
            }"
            :row-selection="{ type: 'checkbox' }"
            :scroll="{ y: gridHeight }"
            @fnGrid="handleGrid"
          >
            <!--            <template #selectAll="{ total }">-->
            <!--              <span v-show="typeValue === '1'">公司（{{ total }}）</span>-->
            <!--              <span v-show="typeValue === '2'">职位（{{ total }}）</span>-->
            <!--            </template>-->

            <template #name="{ text }">
              <div class="nameName">
                <div class="surname" v-if="text?.length > 0">
                  {{ text?.charAt(0) }}
                </div>
                <div class="nameText" :title="text">{{ text }}</div>
              </div>
            </template>
            <!--   修正公司的子级需要换成table表格   -->

            <template #expandedRowRender="{ record }" class="rowClass">
              <div class="item-table">
                <a-table
                  :columns="columnsT"
                  :data-source="record.pageVoList"
                  :pagination="false"
                  :showHeader="false"
                  :bordered="false"
                >
                  <template #name="{ text }">
                    <div class="nameName">
                      <div class="surname" v-if="text?.length > 0">
                        {{ text?.charAt(0) }}
                      </div>
                      <div class="nameText" :title="text">{{ text }}</div>
                    </div>
                  </template>

                  <template #pageVoList="{ record }">
                    <span v-for="(i, index) in record.infoList" :key="i.id">
                      {{ i.phone }}
                      <span v-if="index !== record.infoList.length - 1">,</span>
                    </span>
                  </template>
                  <template #operation="{ record }">
                    <operation :options="optionsT" :record="record" />
                  </template>
                </a-table>
              </div>
            </template>
            <template #pageVoList="{ record }">
              <!--           人数   -->
              <div
                v-if="
                  record.pageVoList !== null && record.pageVoList.length > 0
                "
                class="pageClass"
              >
                {{ record.pageVoList.length }}人
              </div>
              <div class="pageClass" v-else>0人</div>
            </template>

            <template
              #operation="{ record }"
              :class="{ redBg: record.isRepeat === 0 }"
            >
              <operation :options="options" :record="record" />
            </template>
          </grid>
        </div>
      </div>
    </div>
  </div>
  <a-modal
    v-model:visible="visible"
    title="批量修改"
    :width="700"
    :getContainer="container"
    @ok="mergeField"
  >
    <div class="modal-header">
      <a-form :model="formState" layout="inline">
        <a-form-item label="合并名称">
          <a-select
            v-model:value="formState.mergeName"
            :disabled="formState.fixedName?.length > 0"
            show-search
            placeholder="Select a person"
            style="width: 200px"
            :options="optionsHB"
          >
          </a-select>
        </a-form-item>
        <a-form-item label="修改名称">
          <a-input
            v-model:value="formState.fixedName"
            :disabled="formState.mergeName?.length > 0"
            placeholder="请输入修正名称"
            style="width: 200px"
          />
        </a-form-item>
      </a-form>
    </div>
    <div class="modal-tips">
      <a-alert
        message="合并名称与修改名称只可选择一种进行修改。"
        type="warning"
        show-icon
      />
    </div>
    <div class="modal-list">
      <div class="list-item" v-for="(item, index) in state.dataList" :key="index">
        {{ item }}
      </div>
    </div>
  </a-modal>
  <correction-edit
    v-model:visible="visibleEdit"
    :init="initValue"
    @fnOk="handleAddPage"
  />
</template>

<script>
import tipsBar from './components/tipsBar';
import { useRouter, useRoute } from 'vue-router';
import Grid from '@/components/connGrid';
import { reactive, toRefs, ref, watch, createVNode } from 'vue';
import {
  Form,
  Radio,
  // Popconfirm,
  Select,
  Input,
  Alert,
  message,
  Table,
  Modal,
} from 'ant-design-vue';
import dashboardApi from '@/api/dashboard.js';
import { useStore } from 'vuex';
import correctionEdit from '@/views/dashboard/components/correctionEdit';
import operation from '@/components/operation';
// import { Divider, Space, Tooltip } from 'ant-design-vue';
import * as icons from '@ant-design/icons-vue';

export default {
  name: 'correction',
  components: {
    tipsBar,
    Grid,
    ARadioGroup: Radio.Group,
    ARadioButton: Radio.Button,
    // APopconfirm: Popconfirm,
    ASelect: Select,
    AInput: Input,
    AForm: Form,
    AFormItem: Form.Item,
    AAlert: Alert,
    ATable: Table,
    correctionEdit,
    operation,

    // ADivider: Divider,
    // ATooltip: Tooltip,
    // ASpace: Space,
  },
  setup() {
    const gridRef = ref();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const container = ref();
    const state = reactive({
      typeValue: '1',
      visible: false,
      loading: false,
      showTip: false,
      dataList: [],
      formState: {
        mergeName: '',
        fixedName: '',
      },
      contentIds: [],
      visibleEdit: false,
      initValue: {},
    });
    const optionsHB = ref([]);
    const accomplish = () => {
      // 完成导入接口
      dashboardApi
        .accomplish({
          userId: store.state.account.user.id,
          importSituation: route.query.importStatus,
        })
        .then(res => {
          if (res) {
            router.push({
              path: '/contacts',
            });
            state.loading = false;
          } else {
            // message.error('暂无可同步数据');
            if (state.showTip) {
              state.loading = false;
              router.push({
                name: 'dashboard',
                query: {
                  importStatus: route.query.importStatus,
                },
              });
            } else {
              // 没有数据就提示这个， 有数据不提示
              Modal.confirm({
                title: `有效联系人已全部删除，请重新添加有效联系人。`,
                icon: createVNode(icons['ExclamationCircleOutlined']),
                okType: '',
                okText: '好的',
                cancelButtonProps: { style: { display: 'none' } }, // 隐藏取消按钮
                onOk: () => {
                  router.push({
                    name: 'dashboard',
                    query: {
                      importStatus: route.query.importStatus,
                    },
                  });
                  state.loading = false;
                },
              });
            }
          }
        });
    };

    const clickHandle = () => {
      if (state.loading) return;
      state.loading = true;
      // 点击完成调取查询是否有号码重复页面， 有重复就跳转去号码重复页面，没有则直接调取-完成导入页面

      // 点击下一步时调取号码重复接口,有长度点击确认去号码重复页面,否则弹窗ai识别页面
      dashboardApi
        .getRepeatPhone({
          userId: store.state.account.user.id,
          importSituation: route.query.importStatus,
        })
        .then(res => {
          if (res.length > 0) {
            Modal.confirm({
              title: `本次未处理完的无效联系人和疑似重复联系人，您可在首页待清洗联系人中再次处理。`,
              icon: createVNode(icons['ExclamationCircleOutlined']),
              okText: '确认',
              onOk() {
                router.push({
                  name: 'repeatPhone',
                  query: route.query,
                });
                state.loading = false;
                // routeTo('repeatPhone');
              },
            });
          } else {
            // routeTo('dataRecognition');

            // todo 打开弹窗
            // states.visibleAi = true;
            // states.initValue.formTo = 'dashboard';
            // 没有数据不提示、有数据就提示
            if (state.showTip) {
              Modal.confirm({
                title: `请确认您联系人公司和职位名称都已修正完成！`,
                icon: createVNode(icons['ExclamationCircleOutlined']),
                okText: '确认',
                onOk() {
                  state.loading = false;
                  // 完成导入接口
                  accomplish();
                },
              });
            } else {
              // 完成导入接口
              accomplish();
            }
          }
        });
    };
    const filterOption = (input, option) => {
      return option.value.indexOf(input) >= 0;
    };
    const mergeField = () => {
      if (
        state.formState.mergeName.length === 0 &&
        state.formState.fixedName.length === 0
      )
        return message.error('请输入合并后的名称');
      if (state.loading) return;
      state.loading = true;
      dashboardApi
        .mergeField({
          companyOrPositionList: state.dataList,
          updateStr:
            state.formState.mergeName.length > 0
              ? state.formState.mergeName
              : state.formState.fixedName,
          userId: store.state.account.user.id,
          type: state.typeValue,
          importSituation: route.query.importStatus,
          ids: state.contentIds,
        })
        .then(res => {
          if (res) {
            message.success('合并成功');
            state.loading = false;
            state.visible = false;
            gridRef.value.refreshGrid();
          }
        })
        .catch(() => {
          message.error('操作失败');
          state.loading = false;
        });
    };
    watch(
      () => state.visible,
      val => {
        if (!val) {
          state.formState.mergeName = '';
          state.formState.fixedName = '';
          state.dataList = [];
          console.log('valu', )
        }
      }
    );


    // 设置用户导入行为页面信息 /dashboard/correction
    dashboardApi
      .setBehaviorPage({
        userId: store.state.account.user.id,
        importSituation: route.query.importStatus,
        pageName: '/dashboard/correction',
      })
      .then(res => {
        if (res) {
          console.log('res99', res);
        }
      });

    const handleEdit = (record, dataType) => {
      state.visibleEdit = true;
      if (
        !record.infoList ||
        record.infoList.length === 0 ||
        !record.infoList[0].phone
      ) {
        record['infoList'] = [];
        record['infoList'][0] = {
          userId: record.userId,
          phone: '',
        };
      }
      record.dataType = dataType;
      record.type = state.typeValue;
      Object.assign(state.initValue, JSON.parse(JSON.stringify(record)));
    };

    const handleDelete = record => {
      dashboardApi
        .deletePeople({
          userId: store.state.account.user.id,
          ids: [record.id],
        })
        .then(res => {
          if (res) {
            message.success('删除成功');
            state.loading = false;
            gridRef.value.refreshGrid();
          }
        })
        .catch(() => {
          message.error('操作失败');
          state.loading = false;
        });
    };

    return {
      clickHandle,
      mergeField,
      optionsHB,
      gridRef,
      container,
      icons,
      state,
      ...toRefs(state),
      filterOption,
      url: '/connections_treasure_box/contact/import-contact/getValidContactCompanyOrPosition',
      items: [],
      btnOperation: [
        {
          label: '批量修改',
          btnType: 'default',
          permission: 'clue:update',
          fnClick: (ids, data) => {

            if (ids.length && data.length) {
              state.visible = true;
              let resultIds = [];
              ids.forEach(companyName => {
                let matchingData = data.find(item => item.name === companyName);
                if (matchingData) {
                  matchingData.pageVoList.forEach(page => {
                    resultIds.push(page.id);
                  });
                }
              });

              state.dataList = ids;
              state.contentIds = resultIds;
              optionsHB.value = ids.map(i => {
                return {
                  value: i,
                  label: i,
                };
              });
              optionsHB.value.unshift({
                value: '',
                label: '无',
              });
            }
          },
        },
      ],
      columns: [
        {
          title: '公司名称',
          dataIndex: 'name',
          ellipsis: true,
          width: 650,
        },
        {
          title: '人数',
          dataIndex: 'pageVoList',
          slots: { customRender: 'pageVoList' },
          width: 80,
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'id',
          fixed: 'right',
          width: 90,
          slots: { customRender: 'operation' },
        },
      ],
      columnsPos: [
        {
          title: '职位名称',
          dataIndex: 'name',
          ellipsis: true,
          width: 650,
        },
        {
          title: '人数',
          dataIndex: 'pageVoList',
          slots: { customRender: 'pageVoList' },
          width: 80,
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'id',
          fixed: 'right',
          width: 90,
          slots: { customRender: 'operation' },
        },
      ],
      columnsT: [
        {
          dataIndex: 'name',
          width: '150px',
          slots: { customRender: 'name' },
          ellipsis: true,
        },
        {
          dataIndex: 'company',
          width: '200px',
          ellipsis: true,
        },
        {
          dataIndex: 'position',
          width: '200px',
          ellipsis: true,
        },
        // {
        //   dataIndex: 'infoList',
        //   slots: { customRender: 'infoList' },
        //   width: '100',
        //   ellipsis: true,
        // },
        {
          dataIndex: 'pageVoList',
          slots: { customRender: 'pageVoList' },
          width: '120px',
          ellipsis: true,
        },
        {
          key: 'id',
          fixed: 'right',
          width: '100px',
          slots: { customRender: 'operation' },
        },
      ],
      optionsT: [
        {
          type: 'delete',
          permission: 'form:delete',
          fnClick: record => {
            console.log('delete', record);
            handleDelete(record);
          },
        },
        {
          type: 'update',
          label: '编辑',
          permission: 'invalid:update',
          fnClick: record => {
            // console.log('record', record);
            handleEdit(record);
          },
        },
      ],
      options: [
        {
          type: 'update',
          label: '编辑',
          permission: 'invalid:update',
          fnClick: record => {
            // console.log('record', record);
            let dataType = 'company';
            handleEdit(record, dataType);

            // state.visibleEdit = true;
            // if (
            //     !record.infoList ||
            //     record.infoList.length === 0 ||
            //     !record.infoList[0].phone
            // ) {
            //   record['infoList'] = [];
            //   record['infoList'][0] = {
            //     userId: record.userId,
            //     phone: '',
            //   };
            // }
            // Object.assign(state.initValue, JSON.parse(JSON.stringify(record)));
          },
        },
      ],
      handleAddPage(value) {
        state.visibleEdit = value;
        gridRef.value.refreshGrid();
      },
      // 嵌套编辑和删除
      handleClickEdit(record) {
        console.log('record单个编辑', record);
        let dataType = 'all';
        handleEdit(record, dataType);
      },
      handleClick(record) {
        handleDelete(record);
      },
      handleGrid(value) {
        // console.log('value55', value);
        let len = value.length ? value.length : value.records.length;
        if (len > 0) {
          state.showTip = true;
        }
      },
      handleRadio() {
        if (gridRef.value) {
          gridRef.value.refreshGrid();
        }
      },
    };
  },
};
</script>

<style lang="less" scoped>
.theme-bg {
  background: linear-gradient(245deg, #ffedde 1%, #fff8f1 100%);
}
.correction-content {
  width: 90%;
  margin: 0 auto;
  padding-top: 40px;
}
.btn {
  width: 100px;
  height: 42px;
  background: linear-gradient(270deg, #ffb64b 0%, #ff7b00 100%);
  border-radius: 8px;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  line-height: 42px;
  cursor: pointer;
}
.operation {
  margin: 20px 0;
  padding: 20px;
  background-color: #fff;
  position: relative;
  .operation-header {
    display: inline-block;
    position: absolute;
    left: 20px;
    top: 20px;
    .header-text {
      margin-bottom: 10px;
      font-size: 16px;
    }
  }
}
:deep(.ant-modal) {
  top: 60px;
}
.modal-tips {
  margin: 10px 0;
}
.modal-list {
  height: 300px;
  overflow-y: auto;
  .list-item {
    padding: 10px 20px;
    border-bottom: 1px solid #eeeeee;
  }
}
.surname {
  width: 36px;
  height: 36px;
  display: inline-block;
  background-color: @primary-color;
  border-radius: 50%;
  font-size: 16px;
  text-align: center;
  line-height: 36px;
  color: #fff;
  margin-right: 8px;
}
.expanded-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 60px;
  //background: #ffffff;
  border-bottom: 1px solid #eeeeee;
  //padding-right: 20px;
  .cl {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .expanded-name {
      width: 180px;
    }
    .expanded-company {
      width: 240px;
      padding-right: 10px;
    }
    .expanded-position {
      width: 150px;
      padding-right: 10px;
    }
    .expanded-phone {
      width: 240px;
    }
  }
  .cr {
    width: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.expanded-row:hover {
  background: #fff6e6;
}

.noneBorder {
  border-bottom: none;
}
.iss-btn {
  color: @primary-color;
  border: none;
  //background: #fff;
  background: rgba(255, 123, 0, 0.08);
}

.operation-table
  /deep/
  .ant-table.ant-table-middle
  .ant-table-content
  > .ant-table-scroll
  > .ant-table-body
  > table
  > .ant-table-tbody
  > tr
  > td {
  //padding: 0px 6px 0px 16px !important;
}

.operation-table /deep/ .ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  background: none;
}
.operation-table /deep/ tr.ant-table-expanded-row,
tr.ant-table-expanded-row:hover {
  background: #ffffff;
}
.operation-table /deep/ .ant-table {
  max-height: calc(100vh - 400px);
  overflow: auto;
}
:deep(.ant-table-row-expand-icon-cell) {
  padding-left: 21px !important;
}

// 去掉选中后的黄色底纹
.operation-table /deep/ .ant-table-tbody > tr.ant-table-row-selected td {
  background: none;
}

// 加上后操作栏会变白色
.theme-bg /deep/ .ant-table-cell-fix-right {
  background: none;
  text-align: right;
}
.theme-bg/deep/ .ant-table-cell-fix-right {
  background: none;
}

.iss-main /deep/ .ant-table-row-cell-ellipsis,
.ant-table-row-cell-ellipsis .ant-table-column-title {
  white-space: break-spaces;
  text-overflow: ellipsis;
}

.pageClass {
  //text-align: right;
  //display: flex;
  //justify-content: flex-end;
  //align-items: center;
  //width: 90px;
}
.bgHove/deep/.ant-table-tbody:hover {
  background: #fff6e6 !important;
}
.nameName {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .surname {
  }
  .nameText {
    flex: 1;
  }
}
.expanded-name {
  //width: 180px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .surname {
  }
  .nameText {
    width: 125px;
  }
}
.item-table {
  padding-left: 60px;
  :deep(.ant-table-tbody > tr > td) {
    border-bottom: none;
  }
  :deep(.ant-table-scroll) {
    overflow: hidden;
  }
}
:deep(.ant-table-expanded-row > td:nth-child(2)) {
  padding: 0px !important;
}

.item-table /deep/ td {
  padding: 0px;
}
.item-table /deep/ tr.ant-table-expanded-row,
tr.ant-table-expanded-row:hover {
  background: #ffffff;
}
.item-table
  /deep/
  .ant-table-tbody
  > tr:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  //background: #fbfbfb;
}
.rowClass /deep/tr.ant-table-expanded-row,
tr.ant-table-expanded-row:hover {
  background: #ffffff;
}
.operation-table /deep/ thead.ant-table-thead {
  background: #fffaf5;
}
</style>
