<template>
  <div
    class="modalClass"
    ref="container"
    v-if="formState.dataType === 'company'"
  >
    <a-modal
      title="编辑"
      :visible="visible"
      okText="确认"
      :destroyOnClose="true"
      @ok="editCompany"
      :getContainer="container"
      @cancel="handleCancel"
    >
      <a-form
        ref="formRef"
        layout="vertical"
        style="width: 100%"
        :model="formState"
        :rules="rules"
      >
        <a-form-item
          :label="formState.type === '1' ? '公司名称' : '职位名称'"
          name="updateStr"
        >
          <a-input
            v-model:value="formState.updateStr"
            :placeholder="
              formState.type === '1' ? '请输入公司名称' : '请输入职位名称'
            "
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
  <div class="modal-Class" ref="containers" v-else>
    <a-modal
      title="编辑"
      :visible="visible"
      okText="确认"
      :destroyOnClose="true"
      @ok="editPeople"
      :getContainer="containers"
      @cancel="handleCancel"
    >
      <a-form
        ref="formRef"
        layout="vertical"
        style="width: 100%"
        :model="formState"
        :rules="rules"
      >
        <a-form-item label="姓名" name="name">
          <a-input
            v-model:value="formState.name"
            placeholder="请输入联系人姓名"
          />
        </a-form-item>

        <a-form-item
          v-for="(item, index) in formState.infoList"
          :key="item.key"
          :label="index === 0 ? '电话' : ''"
          :name="['infoList', index, 'phone']"
          :rules="rulesPhone"
          :class="{
            bt: index === 0 ? true : false,
          }"
        >
          <a-input
            v-model:value="item.phone"
            placeholder="请输入联系人电话"
            style="width: 93%; margin-right: 8px; height: 32px"
          />
          <!--      :disabled="dynamicValidateForm.domains.length === 1"
                     v-if="dynamicValidateForm.domains.length > 1"
          -->
          <MinusCircleFilled
            class="dynamic-delete-button"
            @click="removeDomain(item)"
          />
        </a-form-item>
        <!--          v-bind="formItemLayoutWithOutLabel"-->
        <a-form-item>
          <a-button type="dashed" style="width: 100%" @click="addDomain">
            添加联系人电话
          </a-button>
        </a-form-item>

        <a-form-item label="公司" name="company">
          <a-input
            v-model:value="formState.company"
            placeholder="请输入联系人公司"
          />
        </a-form-item>
        <a-form-item label="职位" name="position">
          <a-input
            v-model:value="formState.position"
            placeholder="请输入联系人职位"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { Form, message } from 'ant-design-vue';
import { reactive, ref, toRaw, watch, toRefs } from 'vue';
import dashboardApi from '@/api/dashboard';
import { validMobile } from '@/utils/validation';
import { useStore } from 'vuex';
import { MinusCircleFilled } from '@ant-design/icons-vue';
import { useRoute } from 'vue-router';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    MinusCircleFilled,
  },
  props: {
    visible: Boolean,
    init: Object,
  },

  setup(props, context) {
    const store = useStore();
    const route = useRoute();
    const container = ref();
    const containers = ref();
    const formRef = ref();
    const required = { required: true, whitespace: true, message: '不能为空' };
    const state = reactive({
      loading: false,
    });

    const formState = reactive({
      name: '',
      updateStr: '',
      infoList: [],
      company: '',
      position: '',
      type: '',
      dataType: '',
      ids: '',
      userId: store.state.account.user.id,
    });

    watch(
      () => props.init,
      init => {
        // console.log('公司职位弹窗', init);
        // formState = init
        Object.assign(formState, init);
        formState.updateStr = init.name;

        if (init.dataType === 'company') {
          let ids = [];
          init.pageVoList.map(item => {
            ids.push(item.id);
          });
          formState.ids = ids;
        } else {
          formState.ids = '';
        }
      },
      { deep: true }
    );

    const rules = {
      name: [
        {
          required: true,
          message: '请输入姓名',
          trigger: 'change',
        },
      ],
      updateStr: [
        {
          required: true,
          message: '不能为空',
          trigger: 'change',
        },
      ],
      phone: [
        {
          required: true,
          trigger: 'change',
          validator: async () => {
            if (formState.infoList[0]['phone'].trim() === '') {
              return Promise.reject('请输入手机号');
            } else if (!validMobile(formState.infoList[0]['phone'].trim())) {
              return Promise.reject('手机格式不合法');
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
    };

    // 号码校验规则
    // 不能输入字母和文字的正则校验规则
    // 需要比较输入的值跟之前输入的值校验有没有重复，有重复的校验出来
    const rulesPhone = {
      required: true,
      // message: 'domain can not be null',
      trigger: 'change',
      validator: async (rule, value) => {
        console.log('rule', rule, value);
        let allowedCharactersRegex = /^[0-9\s!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/;
        let maxLength50Regex = /^.{1,50}$/;
        if (allowedCharactersRegex.test(value)) {
          if (!maxLength50Regex.test(value)) {
            return Promise.reject('手机号码不能超过56个字符');
          }

          let phoneList = formState.infoList;
          // 需要比较输入的值跟之前输入的值校验有没有重复，有重复的校验出来
          let phoneMap = new Map();
          for (let i = 0; i < phoneList.length; i++) {
            let phone = phoneList[i].phone;

            // 检查Map中是否已经存在该phone值
            // if (phone !== '') {
            if (phoneMap.has(phone)) {
              return Promise.reject('号码与其他号码有重复请修改号码');
            } else {
              // 如果没有重复，将phone值添加到Map中
              state.phoneIndex = '';
              phoneMap.set(phone, i);
            }
            // }
          }
        } else {
          return Promise.reject('手机号码中请勿输入文字和字母');
        }
      },
    };

    const editCompany = () => {
      console.log('formState', formState);
      formRef.value.validateFields().then(() => {
        if (state.loading) return;
        state.loading = true;
        const obj = toRaw(formState);
        delete obj.birthday;
        delete obj.infoList[0].status;
        dashboardApi
          .mergeField({
            userId: store.state.account.user.id,
            ...toRaw(formState),
            importSituation: route.query.importStatus,
          })
          .then(res => {
            if (res) {
              message.success('编辑成功');
              state.loading = false;
              context.emit('fnOk', false);
              // gridRef.value.refreshGrid();
            }
          })
          .catch(() => {
            message.error('操作失败');
            state.loading = false;
          });
      });
    };

    const editPeople = () => {
      console.log('formState', formState);
      formRef.value.validateFields().then(() => {
        if (state.loading) return;
        state.loading = true;
        const obj = toRaw(formState);
        delete obj.birthday;
        delete obj.infoList[0].status;
        dashboardApi
          .updatePeople({
            userId: store.state.account.user.id,
            ...toRaw(formState),
          })
          .then(res => {
            if (res) {
              message.success('编辑成功');
              state.loading = false;
              context.emit('fnOk', false);
              // gridRef.value.refreshGrid();
            }
          })
          .catch(() => {
            message.error('操作失败');
            state.loading = false;
          });
      });
    };

    const dynamicValidateForm = reactive({
      domains: [],
    });

    const removeDomain = item => {
      console.log('item', item, formState.infoList);
      if (formState.infoList.length === 1) {
        return message.info('至少保留一个电话');
      } else {
        let index = formState.infoList.indexOf(item);
        if (index !== -1) {
          formState.infoList.splice(index, 1);
        }
      }
    };
    const addDomain = () => {
      if (formState.infoList.length === 5) {
        return message.error('号码最多只能添加5个');
      } else {
        formState.infoList.push({
          phone: '',
          key: Date.now(),
        });
      }
    };

    const handleCancel = () => {
      context.emit('fnOk', false);
    };

    return {
      container,
      containers,
      editPeople,
      editCompany,
      formState,
      rules,
      rulesPhone,
      handleCancel,
      // ...toRefs(formState),
      ...toRefs(state),
      required,
      //   todo
      formRef,
      dynamicValidateForm,
      removeDomain,
      addDomain,
    };
  },
};
</script>

<style scoped lang="less">
.modalClass /deep/ .ant-modal-body {
  height: 500px;
  overflow: auto;
}
.modal-Class /deep/ .ant-modal-body {
  height: 500px;
  overflow: auto;
}

/*电话号码动态样式*/
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  //color: #999;
  color: #ff7b00;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #ff7b00;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.bt {
  margin-bottom: 0px;
}
.borderB {
  border: 1px solid #fa5151;
}
</style>
